import { Table } from "react-bootstrap";
import { AiFillEdit, AiOutlineLogout } from "react-icons/ai"; //logout icon
import { BsFillArrowLeftCircleFill } from "react-icons/bs"; //logout icon
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactPaginate from "react-paginate";

const Page = () => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(sessionStorage.getItem("id") || "");
  const [showDelete, setShowDelete] = useState(false);
  const [dataAll, setDataAll] = useState([]);
  const [laoding, setLaoding] = useState(true);
  const [error, setError] = useState("");
  const [showDate, setShowDate] = useState(true);
  const [dateStart, setDateStart] = useState("");
  let date = `${new Date().getFullYear() + 50}-0${
    new Date().getMonth() + 1
  }-${new Date().getDate()}`;
  const [dateEnd, setDateEnd] = useState(date);
  const refIdItem = useRef();

  const [pageCount, setPageCount] = useState(1);
  sessionStorage.setItem("id", id);
  useEffect(() => {
    axios
      .get(
        `https://backend.tarefe.net/api/v2/competitions/${id}/forms?${
          dateStart ? `start_date=${dateStart}&00:00:00` : ""
        }&end_date=${dateEnd}&00:00:00`
      )
      .then((response) => {
        if (response) {
          setDataAll(response.data.data.data);
          setLaoding(false);
          setError("");
          setShowDate(!dateEnd || !dateStart ? true : false);
          setPageCount(
            Math.ceil(response.data.data.total / response.data.data.per_page)
          );
        }
      })
      .catch((error) => {
        setLaoding(false);
      });
  }, [dateStart, dateEnd, id]);
  const handlePageClick = (event) => {
    axios
      .get(
        `https://backend.tarefe.net/api/v2/competitions/${id}/forms?page=${
          event.selected + 1
        }`
      )
      .then((res) => {
        setDataAll(res.data.data.data);

        return res.data;
      });
  };

  // delete item
  const deleteItem = async (e) => {
    setShowDelete(true);
    refIdItem.current = e.id;
  };
  const sureDeleteItem = () => {
    try {
      setDataAll(dataAll.filter((p) => p.id !== refIdItem.current));
      axios.delete(
        `https://backend.tarefe.net/api/v2/competitions/${id}/forms/${refIdItem.current}`
      );
    } catch (e) {}
  };

  const Laoding = () => {
    return (
      <tr>
        <td colSpan="5" style={{ borderRight: "none", fontSize: "23px" }}>
          <h1 className="text-center">Laoding...</h1>
        </td>
      </tr>
    );
  };
  // -----------------------------------------------------------------------------
  const [dataWin, setDataWin] = useState([]);
  const [page, setPage] = useState(sessionStorage.getItem("page") || false);
  const [pageCountwin, setPageCountwin] = useState(1);

  useEffect(() => {
    axios
      .get(`https://backend.tarefe.net/api/v2/competitions/${id}/winners`)
      .then((re) => {
        setDataWin(re.data.data.data);
        setPageCountwin(Math.ceil(re.data.data.total / re.data.data.per_page));
      })
      .catch((e) => "");
  }, [page]);

  const handlePageClickWin = (event) => {
    //Paginate
    axios
      .get(
        `https://backend.tarefe.net/api/v2/competitions/${id}/winners?page=${
          event.selected + 1
        }`
      )
      .then((res) => {
        setDataWin(res.data.data.data);
        return res.data;
      });
  };

  const deleteWinnerItem = async (e) => {
    try {
      setDataWin(dataWin.filter((p) => p.id !== e.id));
      axios.delete(`https://backend.tarefe.net/api/winners/${e.id}`);
    } catch (e) {}
  };
  //-------------------------------------------------------------------------------------
  const [dataCompetitions, setDataCompetitions] = useState([]);
  const [postdataCompetitions, setPostDataCompetitions] = useState();
  const [editData, setEditData] = useState();
  const [postdataCompetitionsId, setPostDataCompetitionsId] = useState(null);
  const [popCompetitions, setPopCompetitions] = useState(false);
  const [pageCountCompetitions, setPageCountCompetitions] = useState(1);

  useEffect(() => {
    axios
      .get(
        postdataCompetitionsId
          ? `https://backend.tarefe.net/api/v2/competitions/${postdataCompetitionsId}`
          : "https://backend.tarefe.net/api/v2/competitions"
      )
      .then((re) => {
        setDataCompetitions(postdataCompetitionsId ? [] : re.data.data.data);
        setEditData(postdataCompetitionsId ? re.data.data : "");
        setPageCountCompetitions(
          Math.ceil(re.data.data.total / re.data.data.per_page)
        );
      })
      .catch((e) => "");
  }, [postdataCompetitionsId]);
  const handlePageClickCompetitions = (event) => {
    //Paginate
    axios
      .get(
        `https://backend.tarefe.net/api/v2/competitions?page=${
          event.selected + 1
        }`
      )
      .then((res) => {
        setDataCompetitions(res.data.data.data);
        return res.data;
      });
  };
  const postData = () => {
    axios
      .post(
        postdataCompetitionsId
          ? `https://backend.tarefe.net/api/v2/competitions/${postdataCompetitionsId}`
          : `https://backend.tarefe.net/api/v2/competitions`,
        {
          name: postdataCompetitions,
        }
      )
      .then((res) => {
        setPostDataCompetitionsId(null);
        setPopCompetitions(false);
      })
      .catch((e) => "");
  };
  const deleteCompetitionsItem = async (e) => {
    try {
      setDataCompetitions(dataCompetitions.filter((p) => p.id !== e.id));
      axios.delete(`https://backend.tarefe.net/api/v2/competitions/${e.id}`);
    } catch (e) {}
  };
  useEffect(() => {
    setEditData(editData?.name);
  }, [editData]);
  return (
    <>
      <div
        className={`dark  ${open ? "" : "d-none"} `}
        onClick={() => setOpen(false)}
      ></div>
      <div
        className={`container page  flex-column w-100 h-100 ${
          page ? "d-none" : id ? "d-flex" : "d-none"
        }`}
      >
        <div className="py-2">
          <img src="./logo.png" alt="" />
        </div>
        <span
          className=" fs-1 m-3 text-light"
          style={{
            cursor: "pointer",
            borderRadius: "50%",
            width: " 100%",
          }}
          onClick={() => {
            setPage(false);
            setId("");
            sessionStorage.removeItem("id");
          }}
        >
          <BsFillArrowLeftCircleFill color="#762a8d" />
        </span>
        <div className="btn-fllter d-lg-flex d-block align-items-center">
          <div className="fs-5 fw-bold winsss mx-2">
            <a
              onClick={() => setShowDate(!dateEnd || !dateStart ? true : false)}
              href={`https://backend.tarefe.net/export/${id}/excel`}
            >
              Export Excel
            </a>
          </div>
          <div className="fs-5 fw-bold winsss">
            <a
              onClick={() => setShowDate(!dateEnd || !dateStart ? true : false)}
              href={`https://backend.tarefe.net/export/${id}/pdf`}
            >
              Export PDF
            </a>
          </div>
          <div className="position-relative">
            <span>Start Date</span>

            <input type="date" onChange={(e) => setDateStart(e.target.value)} />
          </div>
          <div className="position-relative">
            <span>End Date</span>
            <input type="date" onChange={(e) => setDateEnd(e.target.value)} />
          </div>
          <div
            onClick={() => {
              setPage(true);
              window.sessionStorage.setItem("page", true);
            }}
            className="fs-5 fw-bold winsss"
          >
            Winners
          </div>
        </div>
        <div className="w-100 oo">
          <Table className=" text-center tt m-auto">
            <thead>
              <tr>
                <th> Name</th>
                <th> Rank</th>
                <th>Phone Number</th>
                <th>Address</th>
                <th>Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {laoding ? (
                <Laoding />
              ) : dataAll.length <= 0 ? (
                <tr>
                  <td
                    colSpan="5"
                    style={{ borderRight: "none", fontSize: "23px" }}
                  >
                    There is no data to display
                  </td>
                </tr>
              ) : (
                dataAll.map((e) => (
                  <tr key={e.id}>
                    <td>{e.name}</td>
                    <td>{e.rank}</td>
                    <td>{e.phone_number}</td>
                    <td>{e.address ? e.address : "__"}</td>
                    <td>{e.created_at}</td>
                    <td>
                      {e.name ? (
                        <img
                          src="./delete.png"
                          alt=""
                          width={25}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            deleteItem(e);
                          }}
                        />
                      ) : (
                        <div className="p-3"></div>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
        <h1 className="d-flex m-auto"> {error ? error : null}</h1>
        <ReactPaginate
          className="pagination"
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          pageClassName="page-item"
          pageLinkClassName="page-link"
        />
      </div>
      <div
        className={`container page  flex-column w-100 h-100 ${
          page ? "d-flex" : "d-none"
        }`}
      >
        <div className="py-2">
          <img src="./logo.png" alt="" />
        </div>
        <span
          className=" fs-1 m-3 text-light"
          style={{
            cursor: "pointer",
            borderRadius: "50%",
            width: " 100%",
          }}
          onClick={() => {
            setPage(false);
            window.sessionStorage.removeItem("page");
          }}
        >
          <BsFillArrowLeftCircleFill color="#762a8d" />
        </span>
        <div className="w-100 oo">
          <Table className=" text-center tt m-auto">
            <thead>
              <tr>
                <th>Winner's name</th>
                <th>Number</th>
                <th>Address</th>
                <th>Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {dataWin.map((e) => (
                <tr key={e.id}>
                  <td>{e.name}</td>
                  <td>{e.phone_number}</td>
                  <td>{e.address}</td>
                  <td>{e.created_at}</td>
                  <td>
                    <Delete clickDelete={() => deleteWinnerItem(e)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <h1 className="d-flex m-auto"> {error ? error : null}</h1>
        <ReactPaginate
          className="pagination"
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          pageCount={pageCountwin}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClickWin}
          pageClassName="page-item"
          pageLinkClassName="page-link"
        />
      </div>
      {/* --------------------------------------------------------------- */}
      <div
        className={`container page  flex-column w-100 h-100 ${
          id ? "d-none" : "d-flex"
        } ${page ? "d-none" : "d-flex"}`}
      >
        <div className="py-2">
          <img src="./logo.png" alt="" />
        </div>
        <div
          className="d-flex justify-content-center align-items-center fs-1 m-3 text-light"
          style={{
            cursor: "pointer",
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            background: "var(--linear-gr)",
          }}
          onClick={() => setPopCompetitions(true)}
        >
          +
        </div>
        <div className="w-100 oo">
          <Table className=" text-center tt m-auto">
            <thead>
              <tr>
                <th>Competitions</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {postdataCompetitionsId === null &&
                dataCompetitions?.map((e) => (
                  <tr key={e.id}>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => setId(e.id)}
                    >
                      {e.name}
                    </td>
                    <td>
                      <Delete clickDelete={() => deleteCompetitionsItem(e)} />
                      <AiFillEdit
                        size={35}
                        className="mx-3"
                        style={{ cursor: "pointer" }}
                        color="#762a8d"
                        onClick={() => {
                          setPostDataCompetitionsId(e.id);
                          setPopCompetitions(true);
                        }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        <h1 className="d-flex m-auto"> {error ? error : null}</h1>
        <ReactPaginate
          className="pagination"
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          pageCount={pageCountCompetitions}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClickCompetitions}
          pageClassName="page-item"
          pageLinkClassName="page-link"
        />
      </div>
      <div className={`messge ${popCompetitions ? "d-block" : "d-none"}`}>
        <div className={`pop w-50`}>
          <div className="btn-fllter d-flex align-items-center justify-content-center flex-column">
            <div
              className="xx"
              onClick={() => {
                setPostDataCompetitionsId(null);
                setPopCompetitions(false);
              }}
            >
              X
            </div>
            <div className="position-relative">
              <span>Name</span>
              <input
                type="text"
                value={editData}
                onChange={(e) => setPostDataCompetitions(e.target.value)}
              />
            </div>

            <div className="position-relative ">
              <button
                type="submit"
                className="button w-100 text-light px-5"
                onClick={() => {
                  postData();
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* --------------------------------------------------------------- */}

      <div>
        <div className={`Off-canvas ${open ? "open" : ""}`}>
          <div
            className="sett"
            style={{ cursor: "pointer" }}
            onClick={() => setOpen(!open)}
          >
            {">"}
            <img src="./Rectangle.png" alt="" />
          </div>
          <div className="h-logo">
            <img src="./logo.png" alt="" />
          </div>
          <div className="body-p">
            <ul>
              {id && <li></li>}
              <li>
                <Link to={"/changepassword"}>Change Password</Link>
              </li>
              <li>
                <Link to={"/trashed"}>Archive</Link>
              </li>
            </ul>
            <div>
              <Link
                to={"/login"}
                onClick={() => {
                  window.localStorage.removeItem("token");
                }}
              >
                <AiOutlineLogout size={25} color="#9d3cb3" className="mx-2" />
                <span>Log Out</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* ------------------------------------------------------------ */}
      <div className={`messge ${showDelete ? "" : "d-none"}`}>
        <div>
          <h5>Are you sure to delete this item?</h5>
          <div>
            <button
              onClick={() => {
                sureDeleteItem();
                setShowDelete(false);
              }}
            >
              Yes
            </button>
            <button onClick={() => setShowDelete(false)}>No</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Page;

const Delete = (props) => {
  const [deletex, setDeletex] = useState(false);
  return (
    <>
      <div
        className={`messge  ${deletex ? "" : "d-none"}`}
        onClick={() => setDeletex(false)}
      >
        <div>
          <h5>Are you sure to delete this item?</h5>
          <div>
            <button onClick={props.clickDelete}>Yes</button>
            <button onClick={() => setDeletex(false)}>No</button>
          </div>
        </div>
      </div>
      <img
        src="./delete.png"
        alt=""
        width={25}
        className="mx-3"
        style={{ cursor: "pointer" }}
        onClick={() => setDeletex(true)}
      />
    </>
  );
};
